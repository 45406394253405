import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'

import { ContacttBanerWrapper, BannerTitle, Headline, ImageWrapper } from './contactBanner.css'

// import ContactImage from '../../images/contactImage.svg'

const ContactBanner = () => {

  // const imgContactWrapper = useRef(null);

  // useEffect(() => {

  //   const div = imgContactWrapper.current;
  //   const [elements] = imgContactWrapper.current.children;

  //   const phoneAnim = elements.getElementById('PhoneAnim')
  //   const mailIcons = elements.getElementById('MailIcons')
  //   const bottomPhone= elements.getElementById('dolTelefonu')

  //   const mailIconsItems = [...mailIcons.children]

  //   gsap.set(phoneAnim, {autoAlpha: 0, y: '-150'});
  //   gsap.set(mailIconsItems, {autoAlpha: 0, y: '-100'});
  //   gsap.set(bottomPhone, {autoAlpha: 0, y: '100'});

  //   const tlContact = gsap.timeline();

  //   tlContact.to(div, {opacity: 1}, 0.3)
  //     .to(phoneAnim, {duration: .6, autoAlpha: 1, y: 0})
  //     .to(mailIconsItems, {duration: 1, autoAlpha: 1, y: 0, stagger: 0.1}, '-=0.1')
  //     .to(phoneAnim, {duration: .6, autoAlpha: 1, y: 0}, '-=0.8')
  
  // }, []);

  const parentContact = {
    initial: {y: 800},
    animate: {
      y: 0,
      transition: {
        staggerChildren: .2,
      },
    },
  }

  const childContact = {
    initial: {y: 800},
    animate: {
      y: 0,
      transition: {
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.9],
      },
    },
  }

  return (
    <ContacttBanerWrapper>
      <BannerTitle variants={parentContact} initial='initial' animate='animate'>
        <Headline variants={childContact}>Napisz</Headline>
        <Headline variants={childContact}>do nas</Headline>
      </BannerTitle>

      {/* <ImageWrapper ref={imgContactWrapper}>
        <ContactImage/>
      </ImageWrapper> */}
    </ContacttBanerWrapper>
  )
}

export default ContactBanner
