import styled, { css } from 'styled-components';


export const FormDiv = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  ${({theme}) => theme.media.tablet} {
    padding: 3rem 1.5rem;
  }

  ${({theme}) => theme.media.laptop} {
    margin: 0 auto;
  }

  ${({theme}) => theme.media.laptopXL} {
    width: 70%;
  }
`;

export const HeaderForm = styled.div`
  width:100%;
`;

export const H3 = styled.h3`
  line-height: 1.4;
  font-size: 1.5rem;
  color: ${({theme}) => theme.color};

  ${({theme}) => theme.media.laptop} {
    padding: 1rem;
    line-height: 1.3;
    font-size: 2.1rem;
  }
`;

export const Form = styled.form`
  width: 100%;
  padding: 1rem 0;

  ${({theme}) => theme.media.laptop} {
    padding: 1rem;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  font-size: .7rem;
  color: ${({ischeck}) => ischeck ? `${({theme}) => theme.colors.mainBlack}` : `#FF4A37`};
  font-weight: ${({ischeck}) => ischeck ? 600 : 400};
  font-family: ${({theme}) => theme.font.mainFont};

  ${props => props.centerBox && css`
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
  `}
`;

export const Input = styled.input`
  font-family: ${({theme}) => theme.font.mainFont};
  width: 100%;
  padding: 1rem;
  margin: .5rem 0;
  border: 3px solid ${({theme}) => theme.colors.mainBlue};

  :focus {
    outline: none;
  }
`;

export const Textarea = styled.textarea`
  font-family: ${({theme}) => theme.font.mainFont};
  width: 100%;
  padding: 1rem;
  margin: .5rem 0;
  border: 3px solid ${({theme}) => theme.colors.mainBlue};
`;

export const InputCheckBox = styled.input`
  margin-right: .4rem;
  font-size: .6rem;
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  color: ${({theme}) => theme.colors.mainWhite};
  background: ${({theme}) => theme.colors.mainRed};
  padding: .5rem 1.3rem;
  font-size: 1.3rem;
  text-align: left;
  line-height: 1;
  font-weight: 400;
  border: none;

  span {
    margin-right: 1.5rem;
    display: block;
  }
`;

export const FinaleMsg = styled.p`
  color: ${({theme}) => theme.colors.mainBlack};
`;