import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { Container } from '../globalStyles.css'
import { ContactAddressWrapper, MainAddress, MainSocial } from './contactAddress.css'


const ContactAddress = ({ onCursor }) => {
  return (
    <Container>
      <ContactAddressWrapper>
        <h2>BluStream <span>TV</span></h2>
        <MainAddress>
          <p>85-703 Bydgoszcz, ul. Kijowksa 44</p>
          <a href="tel:577799031"
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
          >
            <FontAwesomeIcon icon={faArrowRight} /> 577 799 031
          </a>
          <a href="mailto:kontakt@blustreamtv.pl"
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
          >
            <FontAwesomeIcon icon={faArrowRight} /> kontakt@blustreamtv.pl
          </a>
        </MainAddress>
        <MainSocial>
          <a
            href="https://www.facebook.com/ePulpit24" target="_blank" rel="noreferrer"
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            href="https://www.linkedin.com/company/18892638/" target="_blank" rel="noreferrer"
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCI_HLm7_fkf2N5JJv9uCSQA" target="_blank" rel="noreferrer"
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
          >
            <FontAwesomeIcon icon={faYoutubeSquare} />
          </a>
        </MainSocial>
      </ContactAddressWrapper>
    </Container>
  )
}

export default ContactAddress
