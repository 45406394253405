import styled from 'styled-components';


export const ContactAddressWrapper = styled.section`
  width: 100%;
  margin: 0 auto 5rem;
  padding: 0;

  ${({theme}) => theme.media.laptop} {
    margin: 0 auto;
    padding: 2.5rem;
    margin: 0 auto 5rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    width: 70%;
    padding: 1rem 2.5rem;
    margin: 0 auto 6rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: .5rem 2.5rem;
    margin: 0 auto 8rem;
  }

  h2 {
    margin-bottom: 1rem;
    color: ${({theme}) => theme.colors.mainBlue};

    span {
      color: ${({theme}) => theme.colors.mainRed};
    }
  }
`


export const MainAddress = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  
  p {
    color: ${({theme}) => theme.color};
    line-height: 1.7;
    font-weight: 600;
  }

  a {
    color: ${({theme}) => theme.color};
    font-weight: 600;
    line-height: 1.7;
    transform: translateX(-1.1rem);
    transition: .3s;

    &:hover {
      color: ${({theme}) => theme.colors.mainBlue}
    }

    svg {
      color: ${({theme}) => theme.colors.mainBlue};
    }
  }
`


export const MainSocial = styled.div`

  a {
    margin: 0 .5rem;
    font-size: 1.3rem;
    color: ${({theme}) => theme.color};

    &:hover {
      color: ${({theme}) => theme.colors.mainBlue}
    }

    ${({theme}) => theme.media.laptop} {
      font-size: 1.4rem;
    }
  }
`