import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { Container } from '../globalStyles.css'
import { FormDiv, HeaderForm, H3, Form, FormWrapper, Input, Textarea, InputCheckBox, Button, FinaleMsg } from './contactForm.css'

import ArrowImg from '../../images/arrow-bg.png'

const checkboxAcceptance = 'Wyrażam zgodę na przetwarzanie moich danych osobowych przez BluSoft Sp. z o.o. w celu i w zakresie niezbędnym do realizacji obsługi niniejszego zapytania.';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const API_PATH = 'https://maileepulpit.herokuapp.com/sendMail1';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('imię i nazwisko jest wymagane'),
  email: Yup.string().email('adres email jest nieprawidłowy').required('adres e-mail jest wymagany'),
  phone: Yup.string().matches(phoneRegExp, 'numer telefonu jest nieprawidłowy'),
  textarea: Yup.string().required('wpisz wiadomość'),
  checkbox: Yup.bool()
  .test(
    'checkbox',
    'wymagana jest zgoda na przetwarzanie danych',
    value => value === true
  )
  .required()
});

const ContactForm = ({ onCursor }) => {

  const [sendMsg2, setSend2] = useState('')

  return (
    <Container>
      <img className="arrow-image" src={ArrowImg} alt="arrow"/>
      <FormDiv>
        <HeaderForm>
          <H3>Chcesz transmitować imprezę lub jakieś wydażrznie w internecie? Zostaw nam wiadomość.</H3>
        </HeaderForm>
        <Formik 
          initialValues={{ name: '', email: '', phone: '', textarea: '', checkbox: false }} 
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) =>{
            setSubmitting(true);
    
            const qs = require('qs');

            axios.post(`${API_PATH}`, qs.stringify(values, { parseArrays: false } ))
              .then(response => {
                if(response.status === 200) {
                  setSend2('ok');
                  setTimeout(() => {
                    setSend2('');
                  }, 3000)  
                }
              setSubmitting(false);
              values.checkbox = false
              resetForm()
            })
              .catch(error => {
              console.log(error)
              setSend2('notok');
              setTimeout(() => {
                setSend2('');
              }, 3000)
            })
            // setSubmitting(false);
            // resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormWrapper>
                <Input
                  type="text" 
                  name="name" 
                  id="name" 
                  autoComplete="off"
                  placeholder="Imię*" 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {touched.name && errors.name ? <p>{errors.name}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper>
                <Input
                  type="email" 
                  name="email" 
                  id="email" 
                  autoComplete="off"
                  placeholder="E-mail*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {touched.email && errors.email ? <p>{errors.email}</p> : <p></p>}
                <Input
                  type="phone" 
                  name="phone" 
                  id="phone" 
                  autoComplete="off"
                  placeholder="Telefon"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                />
                {touched.phone && errors.phone ? <p>{errors.phone}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper>
                <Textarea
                  type="textarea" 
                  name="textarea" 
                  id="textarea" 
                  rows="7"
                  placeholder="Wiadomość*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.textarea}
                />
                {touched.textarea && errors.textarea ? <p>{errors.textarea}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper ischeck="ischeck">
                <InputCheckBox
                  type="checkbox"
                  name="checkbox" 
                  id="checkbox" 
                  onChange={handleChange}
                  value={values.checkbox}
                />{checkboxAcceptance}
                {touched.checkbox && errors.checkbox ? <p>{errors.checkbox}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper centerBox>
                <Button 
                  type="submit" 
                  disabled={isSubmitting}
                  onMouseEnter={() => onCursor('pointer')}
                  onMouseLeave={onCursor}
                >
                  <span>Wyślij</span><FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </FormWrapper>

              {sendMsg2 === 'ok' ? <FinaleMsg>Wiadomość została wysłana</FinaleMsg> : <p></p>}
              {sendMsg2 === 'notok' ? <FinaleMsg>Nie udało się wysłać wiadomości</FinaleMsg> : <p></p>}
            </Form>
          )}
        </Formik>

        </FormDiv>
      </Container>
  )
}

export default ContactForm;