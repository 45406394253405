import React from 'react'

import SEO from "../components/seo"
import Layout from "../components/layout"

import ContactBanner from '../components/contactPage/contactBanner'
import ContactForm from '../components/contactPage/contactForm'
import ContactAddress from '../components/contactPage/contactAddress'

import { useGlobalStateContext, useGlobalDispatchContext } from '../context/globalContext'


const ContactPage = (props) => {

  const { cursorStyles } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType })
  }

  return (
    <Layout>
      <SEO title="Kontakt - BluStreamTV" />

      <ContactBanner />
      <ContactForm onCursor={onCursor}/>
      <ContactAddress onCursor={onCursor}/>

    </Layout>
  )
}

export default ContactPage